<template>
  <div>
    <b-modal
      data-bv
      id="modal-detalhes-volume"
      body-class="rounded"
      size="lg sm"
      hide-footer
      hide-header
      centered
    >
      <div class="mt-2">
        <div class="d-block text-left my-3">
          <h4 class="font-weight-bold">Detalhar Caixas</h4>
        </div>
        <div>
          <h5 class="font-weight-bold">
            Quantidade de caixas por linha:
            {{ volumes.length }}
          </h5>
        </div>
        <div style="overflow-y: auto; max-height: 600px">
          <div>
            <div v-for="(v, index) in volumes" :key="index">
              <table>
                <thead class="text-white">
                  <tr>
                    <th style="background-color: #002233 !important">
                      Caixa {{ v.index }} - {{ v.codigo }}
                    </th>
                  </tr>
                </thead>
              </table>
              <div>
                <b-button
                  v-if="isAdmin"
                  :disabled="!v.codigo.length"
                  class="button-etiqueta"
                  @click="btnGerarEtiqueta(v.codigo)"
                  >Gerar Etiqueta</b-button
                >
              </div>
              <listagem-insumos-detalhes-linha-paineis
                :insumos="insumosFiltered(v)"
                :volumes="volumesFiltered(v)"
                :indexVol="index"
                :total-insumos="insumos.length"
                :detalhes="true"
                :loading="false"
                v-on="$listeners"
                @mover-insumo="moverInsumo($event)"
              />
              <!--      </div>-->
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div>
      <modal-etiqueta :codigoCaixa="codigoCaixa" />
    </div>
  </div>
</template>
<script>
import ListagemInsumosDetalhesLinhaPaineis from "./ListagemInsumosDetalhesLinhaPaineis";
import { VolumeRepository } from "../../../../../../../repositories/v2/VolumeRepository";
import { UsuarioLogado } from "../../../../../../../services/login/UsuarioLogado";
import ModalEtiqueta from "../../../Etiqueta/ModalEtiqueta.vue";

export default {
  components: {
    ListagemInsumosDetalhesLinhaPaineis,
    ModalEtiqueta,
  },
  name: "ModalDetalhesVolume",
  data: () => ({
    volumeRepository: VolumeRepository.build(),
    codigoCaixa: "",
  }),
  created() {
    this.volumeRepository = VolumeRepository.build();
  },
  props: {
    volumes: { type: Array, required: true },
    insumos: { type: Array, required: true },
  },
  // computed: {
  //   insumosFiltered(v) {
  //     return this.insumos.filter(i => i.volumeId === v.volumeId)
  //   },
  //   volumesFiltered(v) {
  //     this.volumes.filter(vol => vol.volumeId === v.volumeId)
  //   }
  // },
  computed: {
    isAdmin() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  methods: {
    insumosFiltered(v) {
      return this.insumos.filter((i) => i.volumeId === v.volumeId);
    },
    volumesFiltered(v) {
      return this.volumes.filter((vol) => vol.volumeId === v.volumeId);
    },
    moverInsumo({ response }) {
      // console.log({insumoId, volumeIdOrigem, volumeIdDestino})
      this.$emit("mover-insumo", { response });
      // let insumo = this.insumos.find(i => i.insumoId === insumoId)
      // console.log({insumo})
      // for (let j = 0 ; j < this.volumes.length; j++) {
      //   if (this.volumes[j].volumeId !== volumeIdDestino)
      //   for (let i = 0; i < this.volumes[j].insumos.length; i++) {
      //     if (this.volumes[j].insumos[i].insumoId === insumoId) {
      //       console.log('found')
      //       console.log(this.volumes[j].insumos)
      //       this.volumes[j].insumos.splice(i,1);
      //       console.log(this.volumes[j].insumos)
      //       console.log(this)
      //       break;
      //     }
      //   }
      // }
      // const volumeDestino = this.volumes.find(({volumeId}) => volumeId === volumeIdDestino);
      // volumeDestino.insumos.push(insumo)
    },
    btnGerarEtiqueta(codigoCaixa) {
      this.codigoCaixa = codigoCaixa;
      this.$bvModal.show("modal-etiqueta");
    },
  },
};
</script>
<style scoped>
.button-etiqueta {
  background: #ff7d27;
  border: none;
  color: #002233;
  font-weight: bold;
}
.button-etiqueta:hover {
  color: white;
}
</style>

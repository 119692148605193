<template>
  <div>
    <div class="log"></div>
    <div class="content">
      <div
        class="box-content"
        v-if="(insumos || {}).length && insumosForamCarregados"
      >
        <div class="pedido-det" v-if="(insumos || {}).length">
          <line-header
            :numeroLinha="1"
            :numeroPedido="pedido.numeroPedido"
            :pedidoTransportadora="pedido.transportadoraNome"
            :pedidoDataSeProd="pedido.dataProducao | dateDMY"
            :pedidoStatus="pedido.status"
            :volumesLinhaPaineis="volumes.length"
            :quantidade-caixas="volumes.length"
            :block-status="pedido.bloqueioStatus"
            @click="zerarBipagemTotal()"
          />

          <b-row align-h="around">
            <default-button-caixa
              :nameButton="'Detalhar Caixas'"
              class="text-center my-4"
              @click="detalharCaixas"
            />
            <default-button-caixa
              v-if="mostraBtnFecharCaixa"
              :nameButton="'Fechar Caixa'"
              class="text-center my-4"
              @click="
                abrirModalSelecaoPallet(
                  insumos[0].winthorCodigoProduto,
                  insumos.filter((item) => item.bipado).length,
                  insumos[0].insumoDescricao
                )
              "
              :disabled="
                !habilitaBtnFecharCaixa || pedido.bloqueioStatus === 'BLOQUEADO'
              "
              :loading="btnFecharCaixaLoading"
            />
            <default-button-caixa
              v-if="mostraBtnFinalizarLinha"
              :disabled="pedido.bloqueioStatus === 'BLOQUEADO'"
              :nameButton="'Finalizar Linha'"
              class="text-center my-4"
              @click="finalizarLinhaPaineis"
              :loading="btnFinalizarLinhaLoading"
            />
            <default-button-caixa
              :disabled="pedido.bloqueioStatus === 'BLOQUEADO'"
              nameButton="Bloquear Pedido"
              class="text-center my-4"
              @click="openBlockOrderModal"
            />
          </b-row>

          <div class="listagemVolumes">
            <listagem-volumes-linha-paineis
              v-if="pedido.bloqueioStatus !== 'BLOQUEADO'"
              :key="volumeKey"
              :insumos="insumos"
              :volumes="volumes"
              :loading="volumesLoading"
              @abrirModalMoverInsumo="abrirModalMoverInsumo"
              @validarBipagem="validarBipagem"
            />
          </div>
        </div>
        <modal-detalhes-volume
          :insumosBipados="true"
          :insumos="insumos"
          :volumes="volumes"
          @mover-insumo="moverInsumo($event)"
        />
      </div>
      <div v-else>
        <alerta-de-espera />
      </div>
    </div>
    <div>
      <modal-fechar-caixa :modalAlert="modalAlert" />
    </div>
    <div>
      <modal-usuario-iniciador :alertUsuarioIniciador="alertUsuarioIniciador" />
    </div>
    <div>
      <modal-selecionar-pallet
        v-if="isSelectPalletDialogVisible"
        :isSelectPalletDialogVisible="isSelectPalletDialogVisible"
        :selectPalletDialogData="selectPalletDialogData"
        :isLoadingButtonSelectPalletDialog="isLoadingButtonSelectPalletDialog"
        @closeSelectPalletDialog="closeSelectPalletDialog"
        @habilitaFecharCaixa="fecharCaixa"
      />
    </div>
    <block-order
      v-model="isBlockOrderModalOpen"
      :order="pedido.numeroPedido"
      :is-loading="isBlockOrderButtonLoading"
      :order-id="pedido.pedidoId"
      @blockOrder="blockOrder"
      :panels="insumos"
      :key="'modalKey' + modalKey"
    />
    <modal-alert-painel
      :modalAlert="modalAlertPainel"
      :painelNameList="painelNameList"
      @tryAgain="tryAgainCheckPallet"
    />
  </div>
</template>

<script>
import routesValidationMixin from "../../../../../../../mixins/routesValidationMixin";
import httpRequestMixin from "../../../../../../../mixins/httpRequestMixin";
import { LinhaPaineisRenderService } from "../../../../../../../services/pedido_web_producao/linha_paineis/LinhaPaineisRenderService";
import { RecaptchaService } from "../../../../../../../services/RecaptchaService";
import { FinalizacaoLinhaPaineisService } from "../../../../../../../services/pedido_web_producao/linha_paineis/FinalizacaoLinhaPaineisService";
import { ZeragemBipagemTotalService } from "../../../../../../../services/pedido_web_producao/ZeragemBipagemTotalService";
import { zerarLinha } from "../../../../../../../services/pedido_web_producao/zeragemLinhas";
import ListagemVolumesLinhaPaineis from "../../linha_1/components/ListagemVolumesLinhaPaineis.vue";
import DefaultButtonCaixa from "../../../../components/DefaultButtonCaixa.vue";
import LineHeader from "../../../../components/LineHeader.vue";
import ModalDetalhesVolume from "../../linha_1/components/ModalDetalhesVolume.vue";
import AlertaDeEspera from "../../../../../../../components/AlertaDeEspera.vue";
import PedidoWebRepository from "../../../../../../../repositories/v2/PedidoWebRepository";
import router from "../../../../../../../router";
import ModalFecharCaixa from "./../../../ModalFecharCaixa/ModalFecharCaixa.vue";
import Swal from "sweetalert2";
import ModalUsuarioIniciador from "./../../../ModalUsuarioIniciador.vue";
import { UsuarioIniciadorService } from "@/services/pedido_web_producao/UsuarioIniciadorService";
import ModalSelecionarPallet from "../../linha_1/components/ModalSelecionarPallet.vue";
import BlockOrder from "./../../../BlockOrder.vue";
import store from "@/store/store";
import ModalAlertPainel from "../../linha_1/components/ModalAlertPainel.vue";
export default {
  components: {
    AlertaDeEspera,
    ListagemVolumesLinhaPaineis,
    DefaultButtonCaixa,
    LineHeader,
    ModalDetalhesVolume,
    ModalFecharCaixa,
    ModalUsuarioIniciador,
    ModalSelecionarPallet,
    BlockOrder,
    ModalAlertPainel,
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  data() {
    return {
      isBlockOrderModalOpen: false,
      modalAlert: {},
      modalAlertPainel: false,
      volumeKey: 0,
      alertUsuarioIniciador: {},
      volumes: [],
      insumos: [],
      tipoInsumoId: 0,
      pedido: {
        numeroPedido: 0,
        clienteNome: "",
        transportadoraNome: "",
        transportadoraCodigo: 0,
        kitDescricao: "",
        dataProducao: "",
        linhaProducao1Status: "",
        status: "",
      },
      painelNameList: [],
      repositories: {
        pedidoWebRepository: PedidoWebRepository.build(),
      },
      btnFecharCaixaLoading: false,
      btnFinalizarLinhaLoading: false,
      volumesLoading: false,
      usuarioIniciadorService: UsuarioIniciadorService.build(),
      loadingTela: false,
      selectPalletDialogData: {},
      isSelectPalletDialogVisible: false,
      isLoadingButtonSelectPalletDialog: false,
      isBlockOrderButtonLoading: false,
      modalKey: 0,
    };
  },
  async created() {
    this.finalizacaoLinhaPaineisService =
      FinalizacaoLinhaPaineisService.build().setVm(this);
    this.zeragemBipagemTotalService =
      ZeragemBipagemTotalService.build().setVm(this);
    this.linhaPaineisRenderService =
      LinhaPaineisRenderService.build().setVm(this);
  },
  async beforeCreate() {
    this.usuarioIniciadorService = UsuarioIniciadorService.build().setVm(this);
    this.loadingTela = true;
    await this.usuarioIniciadorService.usuarioIniciadorLinhaPaineis(
      this.$route.params.numped
    );
    this.loadingTela = false;
  },
  async mounted() {
    await this.linhaPaineisRenderService.run();
    await RecaptchaService.build(this).applyScript();
  },
  computed: {
    insumosForamCarregados() {
      return !this.loadingTela;
    },
    numSeqBipados() {
      return this.insumos
        .filter((i) => i.bipado && !i.volumeId)
        .map((i) => i.sspedidoNumSeq);
    },
    mostraBtnFecharCaixa() {
      return (
        !this.mostraBtnFinalizarLinha && this.pedido.status !== "FINALIZADO"
      );
    },
    habilitaBtnFecharCaixa() {
      return this.numSeqBipados.length;
    },
    mostraBtnFinalizarLinha() {
      return (
        this.pedido.status !== "FINALIZADO" &&
        !this.volumes.find((v) => v.volumeId === 0)
      );
    },
    habilitaBtnFinalizarLinha() {
      return true;
    },
    volumeCoringa() {
      return this.volumes.find((v) => v.volumeId === 0) || { insumos: [] };
    },
  },
  methods: {
    moverInsumo({ response }) {
      this.linhaPaineisRenderService.adicaoDeDadosResponse(response);
    },
    async finalizarLinhaPaineis() {
      this.btnFinalizarLinhaLoading = true;
      try {
        const response =
          await this.repositories.pedidoWebRepository.finalizarLinhaPaineis(
            this.pedido.numeroPedido
          );
        if (response.status === 200) {
          alert("A linha foi finalizada com sucesso.");
          await router.replace({ name: "pedidosWebAguardandoProducao" });
        } else {
          alert(response.data.message);
        }
      } catch (e) {
        console.log(e.message);
      }
      this.btnFinalizarLinhaLoading = false;
    },
    async validarBipagem(value) {
      const { insumo } = value;

      const zerarSerial = () => {
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].serial = "";
            break;
          }
        }
      };

      const setLoading = (value) => {
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].loading = !!value;
            break;
          }
        }
      };

      const nextInsumoId = (() => {
        let nextIndex = -1;
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            nextIndex = i === this.insumos.length - 1 ? -1 : i + 1;
            break;
          }
        }
        return nextIndex >= 0 ? this.insumos[nextIndex].insumoId : 0;
      })();

      if (!insumo.serial) {
        alert("Esse serial está vazio.");
        return;
      }

      if (
        this.insumos.find(
          (i) => i.serial === insumo.serial && i.insumoId !== insumo.insumoId
        )
      ) {
        alert("Esse serial já foi bipado.");
        zerarSerial();
        return;
      }

      const serialCorreto = (
        this.insumos.find(
          (i) => i.insumoId !== insumo.insumoId && i.bipado && i.serial
        ) || { serial: "" }
      ).serial;
      if (
        serialCorreto.length > 0 &&
        insumo.serial.length !== serialCorreto.length
      ) {
        alert(
          "Provavelmente aconteceu um erro na bipagem. Favor, conferir se o número bipado corresponde ao número correto do painel. (tamanho inconsistente)"
        );
        // zerarSerial();
        // return;
      }

      if (
        serialCorreto.length > 0 &&
        serialCorreto.substr(0, 2) !== insumo.serial.substr(0, 2)
      ) {
        alert(
          "Provavelmente aconteceu um erro na bipagem. Favor, conferir se o número bipado corresponde ao número correto do painel. (iniciais inconsistente)"
        );
        // zerarSerial();
        // return;
      }

      setLoading(true);
      const response =
        await this.repositories.pedidoWebRepository.biparInsumoPaineis({
          serial: insumo.serial,
          insumoId: insumo.insumoId,
        });
      setLoading(false);
      if (response.status === 200) {
        nextInsumoId > 0 &&
          document.querySelector(".insumo-" + nextInsumoId).focus();
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].bipado = true;
            break;
          }
        }
      } else {
        zerarSerial();
        alert(response.data.message);
      }
    },
    detalharCaixas() {
      this.$bvModal.show("modal-detalhes-volume");
    },

    abrirModalSelecaoPallet(codigoWinthor, qntdInsumo, painelNome) {
      this.selectPalletDialogData = {
        codigoWinthor: codigoWinthor,
        qntdInsumo: qntdInsumo,
        painelNome: painelNome,
      };
      this.isSelectPalletDialogVisible = true;
      this.$bvModal.show("modal-selecionar-pallet");
    },

    closeSelectPalletDialog() {
      this.selectPalletDialogData = {};
      this.isSelectPalletDialogVisible = false;
    },

    async fecharCaixa(selectedPallet) {
      if (this.insumos.find((i) => i.loading)) {
        return;
      }
      this.volumesLoading = true;
      const numeroPedido = this.pedido.numeroPedido;
      let body = {
        sspedidoNumSeqList: this.numSeqBipados,
        linha: 1,
        idPallet: selectedPallet,
      };
      try {
        const response =
          await this.repositories.pedidoWebRepository.criarVolumePaineis(
            numeroPedido,
            body
          );
        if (response.status === 200) {
          console.log(response.data);
          this.linhaPaineisRenderService.adicaoDeDadosResponse(response);
          this.volumeKey++;
        }
        if (response.status === 500) {
          await Swal.fire({
            icon: "error",
            text: "Ocorreu um problema no servidor.",
          });
          window.location.reload();
        } else {
          this.modalAlert = {
            message: response.data.message,
            variant: response.status === 200 ? "success" : "danger",
          };
          this.$bvModal.show("modal-fechar-caixa");
        }
      } catch (e) {
        console.log(e.message);
      }
      this.isSelectPalletDialogVisible = false;
      this.volumesLoading = false;
    },
    zerarBipagemTotal() {
      this.zeragemBipagemTotalService.setNumped(this.numeroPedido).run();
    },
    zerarLinha() {
      zerarLinha(this.numeroPedido);
    },
    abrirModalMoverInsumo(value) {
      this.dataModalMoverInsumo = value;
    },
    requestMoverInsumoDeVolume(value) {
      return this.linhaPaineisRenderService.requestMoverInsumoPedido(value);
    },
    async blockOrder(order) {
      this.isBlockOrderButtonLoading = true;
      try {
        const response = await this.repositories.pedidoWebRepository.blockOrder(
          order
        );
        if (response.status === 200) {
          this.isBlockOrderModalOpen = false;
          store.commit(
            "notifications/setSuccessNotification",
            { successStatus: true, successMessage: response.data.message },
            { root: true }
          );
          this.loadingTela = true;
          await this.linhaPaineisRenderService.run();
          this.loadingTela = false;
          return;
        }
        store.commit(
          "notifications/setErrorNotification",
          { errorStatus: true, errorMessage: response.data.message },
          { root: true }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.isBlockOrderButtonLoading = false;
      }
    },
    openBlockOrderModal() {
      this.modalKey++;
      this.isBlockOrderModalOpen = true;
    },
    tryAgainCheckPallet() {
      this.modalAlertPainel = false;
      this.$bvModal.show("modal-selecionar-pallet");
    },
  },
};
</script>

<style scoped>
.listagemVolumes {
  overflow-y: auto;
  max-height: 600px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.groupedInsumos),function(group,index){return _c('table',{key:index},[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"colspan":"3"}},[_vm._v(" SKU: "+_vm._s(group.items[0].insumoDescricao)+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.skuValue[index]),expression:"skuValue[index]"}],staticClass:"prod-cod item-bipagem",attrs:{"type":"text"},domProps:{"value":(_vm.skuValue[index])},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSkuInput($event, index)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.skuValue, index, $event.target.value)}}})]),_c('th',[(!group.skuBipado)?_c('div',[_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"size":"lg","variant":"transparent"}},[_c('b-icon',{attrs:{"icon":"check-circle","scale":"2"}})],1)],1):_c('div',[_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"size":"lg","variant":"transparent"}},[_c('b-icon',{attrs:{"scale":"2","icon":"check-circle-fill","variant":"success"}})],1)],1)]),_c('th',[(group.skuBipado)?_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"aria-pressed":"false","size":"lg","variant":"transparent"},on:{"click":function($event){return _vm.limparValordoSku($event, _vm.skuValue[index], index)}}},[_c('b-icon',{attrs:{"icon":"x-circle","scale":"2","variant":"danger"}})],1):_vm._e()],1)]),_c('tbody',_vm._l((group.items),function(insumo,x){return _c('tr',{key:insumo.insumoId,staticClass:"itens"},[_c('th',{staticStyle:{"padding-left":"3rem"}},[_c('div',{staticClass:"mt-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(insumo.insumoDescricao)+" - "+_vm._s(`${insumo.index}/${_vm.totalInsumos}`)+" ")]),_c('div',{staticClass:"mt-2"},[_c('input',{class:'prod-cod item-bipagem insumo-' +
                insumo.insumoId +
                (insumo.bipado ? ' insumoBipado' : '') +
                (insumo.loading ? ' input-loading' : ''),attrs:{"type":"text","disabled":!_vm.groupedInsumos[index].skuBipado ||
                insumo.bipado ||
                insumo.loading ||
                _vm.loading},domProps:{"value":insumo.serial},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validarBipagem($event, x, insumo)}}})])]),_c('th',[(!insumo.bipado)?_c('div',[_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"size":"lg","variant":"transparent"}},[_c('b-icon',{attrs:{"icon":"check-circle","scale":"2"}})],1)],1):_c('div',[_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"size":"lg","variant":"transparent"}},[_c('b-icon',{attrs:{"scale":"2","icon":"check-circle-fill","variant":"success"}})],1)],1)]),_c('th',[(insumo.bipado && !_vm.detalhes)?_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"aria-pressed":"false","size":"lg","variant":"transparent"},on:{"click":function($event){return _vm.limparValorDaBipagem($event, insumo.insumoId, x)}}},[_c('b-icon',{attrs:{"icon":"x-circle","scale":"2","variant":"danger"}})],1):_vm._e()],1),_c('th',[(insumo.bipado && insumo.serial !== '')?_c('div',[(false)?_c('b-button',{staticClass:"mt-4 rounded-circle p-2",attrs:{"disabled":!_vm.permiteBotaoMoverInsumo || insumo.bipado || insumo.loading,"size":"lg","variant":"transparent","aria-pressed":"false"},on:{"click":function($event){return _vm.modalMoverInsumo(insumo.insumoId, insumo.volumeId)}}},[_c('b-iconstack',{attrs:{"variant":"transparent","font-scale":"1.3"}},[_c('b-icon',{attrs:{"stacked":"","scale":"2","icon":"arrows-move","variant":"warning"}}),_c('b-icon',{attrs:{"stacked":"","scale":"0.3","icon":"circle-fill","variant":"warning"}})],1)],1):_vm._e()],1):_vm._e()])])}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <table v-for="(group, index) in groupedInsumos" :key="index">
      <tr>
        <th colspan="3" class="text-left">
          SKU: {{ group.items[0].insumoDescricao }}
          <input
            type="text"
            v-model="skuValue[index]"
            class="prod-cod item-bipagem"
            @keyup.enter="handleSkuInput($event, index)"
          />
        </th>
        <th>
          <div v-if="!group.skuBipado">
            <b-button
              size="lg"
              variant="transparent"
              class="mt-4 rounded-circle p-2"
            >
              <b-icon icon="check-circle" scale="2"></b-icon>
            </b-button>
          </div>
          <div v-else>
            <b-button
              size="lg"
              variant="transparent"
              class="mt-4 rounded-circle p-2"
            >
              <b-icon
                scale="2"
                icon="check-circle-fill"
                variant="success"
              ></b-icon>
            </b-button>
          </div>
        </th>
        <th>
          <b-button
            v-if="group.skuBipado"
            aria-pressed="false"
            size="lg"
            variant="transparent"
            class="mt-4 rounded-circle p-2"
            @click="limparValordoSku($event, skuValue[index], index)"
          >
            <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
          </b-button>
        </th>
      </tr>
      <tbody>
        <tr
          v-for="(insumo, x) in group.items"
          :key="insumo.insumoId"
          class="itens"
        >
          <th style="padding-left: 3rem">
            <div class="mt-2" style="font-size: 20px">
              {{ insumo.insumoDescricao }} -
              {{ `${insumo.index}/${totalInsumos}` }}
              <!-- {{ insumo.insumoDescricao }} - {{ insumo.index }} -->
            </div>
            <div class="mt-2">
              <input
                type="text"
                :class="
                  'prod-cod item-bipagem insumo-' +
                  insumo.insumoId +
                  (insumo.bipado ? ' insumoBipado' : '') +
                  (insumo.loading ? ' input-loading' : '')
                "
                @keyup.enter="validarBipagem($event, x, insumo)"
                :value="insumo.serial"
                :disabled="
                  !groupedInsumos[index].skuBipado ||
                  insumo.bipado ||
                  insumo.loading ||
                  loading
                "
              />
            </div>
          </th>
          <th>
            <div v-if="!insumo.bipado">
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
              >
                <b-icon icon="check-circle" scale="2"></b-icon>
              </b-button>
            </div>
            <div v-else>
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
              >
                <b-icon
                  scale="2"
                  icon="check-circle-fill"
                  variant="success"
                ></b-icon>
              </b-button>
            </div>
          </th>
          <th>
            <b-button
              v-if="insumo.bipado && !detalhes"
              aria-pressed="false"
              size="lg"
              variant="transparent"
              class="mt-4 rounded-circle p-2"
              @click="limparValorDaBipagem($event, insumo.insumoId, x)"
            >
              <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
            </b-button>
          </th>
          <th>
            <div v-if="insumo.bipado && insumo.serial !== ''">
              <b-button
                v-if="false"
                :disabled="
                  !permiteBotaoMoverInsumo || insumo.bipado || insumo.loading
                "
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
                aria-pressed="false"
                @click="modalMoverInsumo(insumo.insumoId, insumo.volumeId)"
              >
                <b-iconstack variant="transparent" font-scale="1.3">
                  <b-icon
                    stacked
                    scale="2"
                    icon="arrows-move"
                    variant="warning"
                  ></b-icon>
                  <b-icon
                    stacked
                    scale="0.3"
                    icon="circle-fill"
                    variant="warning"
                  ></b-icon>
                </b-iconstack>
              </b-button>
            </div>
            <!--            <div v-else>-->
            <!--              <b-button-->
            <!--                size="lg"-->
            <!--                variant="transparent"-->
            <!--                class="mt-4 rounded-circle p-2"-->
            <!--                aria-pressed="false"-->
            <!--                @click="modalMoverInsumo(insumo.insumoId, insumo.volumeId)"-->
            <!--              >-->
            <!--                <b-iconstack variant="transparent" font-scale="1.3">-->
            <!--                  <b-icon-->
            <!--                    stacked-->
            <!--                    scale="2"-->
            <!--                    icon="arrows-move"-->
            <!--                    variant="warning"-->
            <!--                  ></b-icon>-->
            <!--                  <b-icon-->
            <!--                    stacked-->
            <!--                    scale="0.3"-->
            <!--                    icon="circle-fill"-->
            <!--                    variant="warning"-->
            <!--                  ></b-icon>-->
            <!--                </b-iconstack>-->
            <!--              </b-button>-->
            <!--            </div>-->
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { BipagemService } from "../../../../../../../services/pedido_web_producao/linha_paineis/BipagemService";
import { UsuarioLogado } from "../../../../../../../services/login/UsuarioLogado";
import BipagemInsumosRepository from "../../../../../../../repositories/v2/BipagemInsumoRepository";
import Swal from "sweetalert2";
export default {
  components: {},
  name: "InsumoCard",
  data: () => ({
    bodyMoverInsumo: {},
    skuValue: {},
    allSku: [],
    groupedInsumos: [],
    bipagemService: BipagemService.build(),
    bipagemRepository: BipagemInsumosRepository.build(),
  }),
  async created() {
    this.bipagemService = BipagemService.build().setVm(this);
    this.separetedInsumos();
  },
  props: {
    volumes: { type: Array, required: true },
    insumos: { type: Array, required: true },
    indexVol: { type: Number, required: true },
    totalInsumos: { type: Number, required: true },
    loading: { type: Boolean, required: true },
    detalhes: { type: Boolean, default: false },
  },
  computed: {
    permiteBotaoMoverInsumo() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  methods: {
    handleSkuInput(event, groupIndex) {
      if (this.validateSku(event, groupIndex)) return;

      const currentGroup = this.groupedInsumos[groupIndex];

      if (!currentGroup) {
        console.error("Grupo não encontrado para o índice:", groupIndex);
        return;
      }

      console.log("currentGroup.items:", currentGroup.items);

      const currentIndex = currentGroup.items.findIndex(
        (insumo) => insumo.winthorCodigoProduto === groupIndex
      );

      const nextIndex =
        currentIndex === currentGroup.items.length - 1 ? -1 : currentIndex + 1;

      if (nextIndex >= 0) {
        const nextInsumoId = currentGroup.items[nextIndex].insumoId;
        const nextInput = document.querySelector(`.insumo-${nextInsumoId}`);
        if (nextInput) {
          this.$nextTick(() => {
            const nextInput = document.querySelector(`.insumo-${nextInsumoId}`);
            if (nextInput && !nextInput.disabled) {
              nextInput.focus();
            }
          });
        }
      }
    },

    validateSku(event, index) {
      const { target } = event;
      const sku = target.value;
      console.log(sku);
      console.log(index);
      const skuExists = this.groupedInsumos[index].items.some(
        (insumo) => insumo.winthorCodigoProduto == sku
      );

      console.log(skuExists);
      if (!skuExists) {
        Swal.fire({
          title: "Erro de Validação",
          text: "SKU não encontrado! Verifique o código e tente novamente.",
          icon: "error",
          confirmButtonText: "OK",
        });
        target.value = "";
        target.focus();
        return true;
      }

      this.groupedInsumos[index].skuBipado = true;

      return false;
    },

    separetedInsumos() {
      this.groupedInsumos = this.insumos.reduce((acc, insumo) => {
        if (!acc[insumo.winthorCodigoProduto]) {
          acc[insumo.winthorCodigoProduto] = {
            skuBipado: false,
            items: [],
          };
        }
        acc[insumo.winthorCodigoProduto].items.push(insumo);
        return acc;
      }, {});
    },

    modalMoverInsumo(insumoId, volumeId) {
      let bodyMoverInsumo = {
        insumoIdNumber: insumoId,
        indexVol: this.indexVol,
        volumeId: volumeId,
      };
      this.$emit("abrirModalMoverInsumo", bodyMoverInsumo);
      this.$bvModal.show("modal-mover-insumo");
    },

    async limparValorDaBipagem(event, insumoId) {
      const { target } = event;
      const serial = target.value;
      target.value = "";
      for (let i = 0; i < this.insumos.length; i++) {
        const insumo = this.insumos[i];
        if (insumo.insumoId === insumoId) {
          insumo.bipado = false;
          insumo.serial = "";
        }
      }
      target.focus();
      this.bipagemRepository.deleteBipagem({ insumoId }).then(({ status }) => {
        if (status === 200) return;
        for (let i = 0; i < this.insumos.length; i++) {
          const insumo = this.insumos[i];
          if (insumo.insumoId === insumoId) {
            insumo.bipado = true;
            insumo.serial = serial;
          }
        }
      });
    },

    limparValordoSku(event, skuValue, index) {
      this.groupedInsumos[index].skuBipado = false;
      this.skuValue[index] = "";
    },

    async validarBipagem(event, index, insumo) {
      this.insumos[index].serial = event.target.value;
      let bodyValidarBipagem = {
        insumo: insumo,
        event: event,
        index: index,
      };
      await this.$emit("validarBipagem", bodyValidarBipagem);
    },
  },
};
</script>
<style scoped>
.insumoBipado {
  border: 2px solid green;
  border-radius: 10px;
}

.input-loading {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
}
</style>
